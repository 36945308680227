import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const divisions = {
  "North": {
    "Chengalpattu": ["Chengalpattu"],
    "Chennai": ["Virugambakam", "Perungalathur", "Selaiyur", "Polachery", "Chrompet", "Guduvancherry", "Kolathur"],
    "Dharmapuri":[],
    "Kallakuruchi": ["KallaKuruchi",],
    "Kanchipuram": ["Kanchipuram",],
    "Krishnagiri": ["Krishnagiri",],
    "Ranipet":["Ranipet"],
    "Thiruvallur": [],
    "Thirupathur": [],
    "Thiruvanamalai": ["Thiruvanamalai"],
    "Vellore": ["Gandhi Nagar","Sathuvachari"],
    "Villupuram": ["Gingee"]
  },
  "East": {
    "Ariyalur": [],
    "Cuddalore": [],
    "Mayiladuthurai": [],
    "Nagapattinam": [],
    "Perambalur": [],
    "Puddukottai": [],
    "Thanjavur": [],
    "Thiruvarur": [],
    "Thirichirapalli": [],
  },
  "West": {
    "Coimbatore": ["Ramanathapuram", "Pollachi", "Podanur", "Selvapuram", "Kalapatti", "Ondipudur", "Narasimanaikenpalayam", "RS Puram", "Kuniyamuthur", ""],
     "Dindigul":[],
     "Erode":[],
     "Karur":[],
     "Namakal":[],
     "Salem":["Opposite to New Bus stand"],
     "Tiruppur":["Kangayam","Near Bishop School"],
     "The Nilgiris":[]
  },
  "South": {
    "Madurai":["Iyyer Bungalow","Anna Nagar"],
    "Kaniyakumari":[],
    "Ramanathapuram":["Paramakudi"],
    "Sivagangai":["Karaikudi"],
    "Tenkasi":[" "],
    "Theni":["NRT Nagar","Cumbum"],
    "Thoothukudi":[],
    "Thirunelveli":[],
    "Virudhunagar":[]
  }
};

const Section = styled.section`
  padding: 20px;
  background:white;
  margin-top:40px;
`;

const Division = styled.div`
  margin-top:40px;
`;

const DivisionTitle = styled.h2`
  font-family: 'Helvetica', sans-serif;
  color: #f00d88;
  white-space: nowrap;
`;

const CityList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CityItem = styled.li`
  font-family: 'Helvetica', sans-serif;
  color: #000;
  padding: 5px 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

const CityLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;  /* Adjust padding to control the space around the label */
`;

const CityName = styled.span`
  margin-right: 10px; /* Adjust this to change the space between the name and the icon */
`;

const VillageList = styled.ul`
  list-style-type: none;
  padding-left: 20px;
  margin-top: 5px;
  display: ${props => (props.show ? 'block' : 'none')};
`;

const VillageItem = styled.li`
  font-family: 'Helvetica', sans-serif;
  color: #000;
  padding: 3px 0;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const Section15 = () => {
  const [activeCity, setActiveCity] = useState({});

  const toggleVillages = (division, city) => {
    setActiveCity(prevState => ({
      ...prevState,
      [division]: prevState[division] === city ? null : city,
    }));
  };

  return (
    <Section>
      <h1 style={{ fontFamily: 'Helvetica', color: '#f00d88',textAlign:'center',fontWeight:'bold'}}>TIA Franchise</h1>
      <GridContainer>
        {Object.entries(divisions).map(([division, cities]) => (
          <Division key={division}>
            <DivisionTitle>{division}</DivisionTitle>
            <CityList>
              {Object.entries(cities).map(([city, villages]) => (
                <CityItem key={city}>
                  <CityLabel 
                    onClick={() => villages.length > 0 && toggleVillages(division, city)} // Enable click only if villages exist
                    style={{ cursor: villages.length > 0 ? 'pointer' : 'default' }} // Change cursor based on villages
                  >
                    <CityName>{city}</CityName>
                    {villages.length > 0 && (  // Conditionally render the icon
                      <FontAwesomeIcon icon={activeCity[division] === city ? faAngleUp : faAngleDown} />
                    )}
                  </CityLabel>
                  <VillageList show={activeCity[division] === city}>
                    {villages.map((village, index) => (
                      <VillageItem key={index}>{village}</VillageItem>
                    ))}
                  </VillageList>
                </CityItem>
              ))}
            </CityList>
          </Division>
        ))}
      </GridContainer>
    </Section>
  );
};

export default Section15;

import React, { useState } from 'react';
import styled from 'styled-components';
import { imageURL } from "../../../../../../Api.js";
const image1 = imageURL('photo/IMG-20241029-WA0001.jpg');
const image2 = imageURL('photo/IMG-20241029-WA0002.jpg');
const image3 = imageURL('photo/IMG-20241029-WA0003.jpg');
const image4 = imageURL('photo/IMG-20241029-WA0004.jpg');
const image5 = imageURL('photo/IMG-20241029-WA0005.jpg');
const image6 = imageURL('photo/IMG-20241029-WA0006.jpg');
const image7 = imageURL('photo/IMG-20241029-WA0007.jpg');
const image8 = imageURL('photo/IMG-20241029-WA0008.jpg');
const image9 = imageURL('photo/IMG-20241029-WA0009.jpg');
const image10 = imageURL('photo/IMG-20241029-WA0010.jpg');
const image11 = imageURL('photo/IMG-20241029-WA0011.jpg');
const image12 = imageURL('photo/IMG-20241029-WA0012.jpg');
const image13 = imageURL('photo/IMG-20241029-WA0013.jpg');
const image14 = imageURL('photo/IMG-20241029-WA0014.jpg');
const image15 = imageURL('photo/IMG-20241029-WA0015.jpg');
const image16 = imageURL('photo/IMG-20241029-WA0016.jpg');
const image17 = imageURL('photo/IMG-20241029-WA0017.jpg');
const image18 = imageURL('photo/IMG-20241029-WA0018.jpg');
const image19 = imageURL('photo/IMG-20241029-WA0019.jpg');
const image20 = imageURL('photo/IMG-20241029-WA0020.jpg');
const image21 = imageURL('photo/IMG-20241029-WA0022.jpg');
const image22 = imageURL('photo/IMG-20241029-WA0023.jpg');
const image23 = imageURL('photo/IMG-20241029-WA0024.jpg');
const image24 = imageURL('photo/IMG-20241029-WA0025.jpg');
const image25 = imageURL('photo/IMG-20241029-WA0026.jpg');
const image26 = imageURL('photo/IMG-20241029-WA0027.jpg');
const image27 = imageURL('photo/IMG-20241029-WA0028.jpg');
const image28 = imageURL('photo/IMG-20241029-WA0029.jpg');
const image29 = imageURL('photo/IMG-20241029-WA0030.jpg');
const image30 = imageURL('photo/IMG-20241029-WA0032.jpg');
const image31 = imageURL('photo/IMG-20241029-WA0033.jpg');
const image32 = imageURL('photo/IMG-20241029-WA0034.jpg');
const image33 = imageURL('photo/IMG-20241029-WA0035.jpg');
const image34 = imageURL('photo/IMG-20241029-WA0036.jpg');
const image35 = imageURL('photo/IMG-20241029-WA0037.jpg');
const image36 = imageURL('photo/IMG-20241029-WA0038.jpg');
const image37 = imageURL('photo/IMG-20241029-WA0039.jpg');
const image38 = imageURL('photo/IMG-20241029-WA0040.jpg');
const image39 = imageURL('photo/IMG-20241029-WA0041.jpg');
const image40 = imageURL('photo/IMG-20241029-WA0042.jpg');
const image41 = imageURL('photo/IMG-20241029-WA0043.jpg');
const image42 = imageURL('photo/IMG-20241029-WA0044.jpg');
const image43 = imageURL('photo/IMG-20241029-WA0045.jpg');
const image44 = imageURL('photo/IMG-20241029-WA0046.jpg');
const image45 = imageURL('photo/IMG-20241029-WA0047.jpg');
const image46 = imageURL('photo/IMG-20241029-WA0048.jpg');
const image47 = imageURL('photo/IMG-20241029-WA0049.jpg');
const image48 = imageURL('photo/IMG-20241029-WA0050.jpg');
const image49 = imageURL('photo/IMG-20241029-WA0051.jpg');
const image50 = imageURL('photo/IMG-20241029-WA0052.jpg');
const image51 = imageURL('photo/IMG-20241029-WA0053.jpg');
const image52 = imageURL('photo/IMG-20241029-WA0054.jpg');
const image53 = imageURL('photo/IMG-20241029-WA0055.jpg');
const image54 = imageURL('photo/IMG-20241029-WA0056.jpg');
const image55 = imageURL('photo/IMG-20241029-WA0057.jpg');
const image56 = imageURL('photo/IMG-20241029-WA0058.jpg');
const image57 = imageURL('photo/IMG-20241029-WA0059.jpg');
const image58 = imageURL('photo/IMG-20241029-WA0060.jpg');
const image59 = imageURL('photo/IMG-20241029-WA0061.jpg');
const image60 = imageURL('photo/IMG-20241029-WA0062.jpg');
const image61 = imageURL('photo/IMG-20241029-WA0063.jpg');
const image62 = imageURL('photo/IMG-20241029-WA0064.jpg');
const image63 = imageURL('photo/IMG-20241029-WA0065.jpg');
const image64 = imageURL('photo/IMG-20241029-WA0066.jpg');
const image65 = imageURL('photo/IMG-20241029-WA0067.jpg');
const image66 = imageURL('photo/IMG-20241029-WA0068.jpg');
const image67 = imageURL('photo/IMG-20241029-WA0069.jpg');
const image68 = imageURL('photo/IMG-20241029-WA0070.jpg');
const image69 = imageURL('photo/IMG-20241029-WA0071.jpg');
const image70 = imageURL('photo/IMG-20241029-WA0072.jpg');
const image71 = imageURL('photo/IMG-20241029-WA0073.jpg');
const image72 = imageURL('photo/IMG-20241029-WA0074.jpg');
const image73 = imageURL('photo/IMG-20241029-WA0075.jpg');
const image74 = imageURL('photo/IMG-20241029-WA0076.jpg');
const image75 = imageURL('photo/IMG-20241029-WA0077.jpg');
const image76 = imageURL('photo/IMG-20241029-WA0078.jpg');
const image77 = imageURL('photo/IMG-20241029-WA0079.jpg');
const image78 = imageURL('photo/IMG-20241029-WA0080.jpg');
const image79 = imageURL('photo/IMG-20241029-WA0083.jpg');
const image80 = imageURL('photo/IMG-20241029-WA0084.jpg');
const image81 = imageURL('photo/IMG-20241029-WA0085.jpg');
const image82 = imageURL('photo/IMG-20241029-WA0086.jpg');
const image83 = imageURL('photo/IMG-20241029-WA0087.jpg');
const image84 = imageURL('photo/IMG-20241029-WA0089.jpg');
const image85 = imageURL('photo/IMG-20241029-WA0090.jpg');
const image86 = imageURL('photo/IMG-20241029-WA0091.jpg');
const image87 = imageURL('photo/IMG-20241029-WA0092.jpg');
const image88 = imageURL('photo/IMG-20241029-WA0093.jpg');
const image89 = imageURL('photo/IMG-20241029-WA0094.jpg');
const image90 = imageURL('photo/IMG-20241029-WA0097.jpg');
const image91 = imageURL('photo/IMG-20241029-WA0099.jpg');
const image92 = imageURL('photo/IMG-20241029-WA0100.jpg');
const image93 = imageURL('photo/IMG-20241029-WA0101.jpg');
const image94 = imageURL('photo/IMG-20241029-WA0102.jpg');
const image95 = imageURL('photo/IMG-20241029-WA0103.jpg');
const image96 = imageURL('photo/IMG-20241029-WA0104.jpg');
const image97 = imageURL('photo/IMG-20241029-WA0105.jpg');
const image98 = imageURL('photo/IMG-20241029-WA0106.jpg');
const image99 = imageURL('photo/IMG-20241029-WA0107.jpg');
const image100 = imageURL('photo/IMG-20241029-WA0108.jpg');
const image101 = imageURL('photo/IMG-20241029-WA0109.jpg');
const image102 = imageURL('photo/IMG-20241029-WA0110.jpg');
const image103 = imageURL('photo/IMG-20241029-WA0111.jpg');
const image104 = imageURL('photo/IMG-20241029-WA0112.jpg');
const image105 = imageURL('photo/IMG-20241029-WA0113.jpg');
const image106 = imageURL('photo/IMG-20241029-WA0114.jpg');
const image107 = imageURL('photo/IMG-20241029-WA0115.jpg');
const image108 = imageURL('photo/IMG-20241029-WA0116.jpg');
const image109 = imageURL('photo/IMG-20241029-WA0117.jpg');
const image110 = imageURL('photo/IMG-20241029-WA0118.jpg');
const image111 = imageURL('photo/IMG-20241029-WA0119.jpg');
const image112 = imageURL('photo/IMG-20241029-WA0120.jpg');
const image113 = imageURL('photo/IMG-20241029-WA0121.jpg');
const image114 = imageURL('photo/IMG-20241029-WA0122.jpg');
const image115 = imageURL('photo/IMG-20241029-WA0123.jpg');
const image116 = imageURL('photo/IMG-20241029-WA0124.jpg');
const image117 = imageURL('photo/IMG-20241029-WA0125.jpg');
const image118 = imageURL('photo/IMG-20241029-WA0126.jpg');
const image119 = imageURL('photo/IMG-20241029-WA0127.jpg');
const image120 = imageURL('photo/IMG-20241029-WA0128.jpg');
const image121 = imageURL('photo/IMG-20241029-WA0129.jpg');
const image122 = imageURL('photo/IMG-20241029-WA0130.jpg');
const image123 = imageURL('photo/IMG-20241029-WA0131.jpg');
const image124 = imageURL('photo/IMG-20241029-WA0132.jpg');
const image125 = imageURL('photo/IMG-20241029-WA0133.jpg');
const image126 = imageURL('photo/IMG-20241029-WA0134.jpg');
const image127 = imageURL('photo/IMG-20241029-WA0135.jpg');
const image128 = imageURL('photo/IMG-20241029-WA0136.jpg');
const image129 = imageURL('photo/IMG-20241029-WA0137.jpg');
const image130 = imageURL('photo/IMG-20241029-WA0138.jpg');
const image131 = imageURL('photo/IMG-20241029-WA0139.jpg');
const image132 = imageURL('photo/IMG-20241029-WA0140.jpg');
const image133 = imageURL('photo/IMG-20241029-WA0141.jpg');
const image134 = imageURL('photo/IMG-20241029-WA0142.jpg');
const image135 = imageURL('photo/IMG-20241029-WA0143.jpg');
const image136 = imageURL('photo/IMG-20241029-WA0144.jpg');
const image137 = imageURL('photo/IMG-20241029-WA0145.jpg');
const image138 = imageURL('photo/IMG-20241029-WA0146.jpg');
const image139 = imageURL('photo/IMG-20241029-WA0147.jpg');
const image140 = imageURL('photo/IMG-20241029-WA0148.jpg');
const image141 = imageURL('photo/IMG-20241029-WA0149.jpg');
const image142 = imageURL('photo/IMG-20241029-WA0150.jpg');
const image143 = imageURL('photo/IMG-20241029-WA0152.jpg');
const image144 = imageURL('photo/IMG-20241029-WA0153.jpg');
const image145 = imageURL('photo/IMG-20241029-WA0154.jpg');
const image146 = imageURL('photo/IMG-20241029-WA0155.jpg');
const image147 = imageURL('photo/IMG-20241029-WA0156.jpg');
const image148 = imageURL('photo/IMG-20241029-WA0157.jpg');
const image149 = imageURL('photo/IMG-20241029-WA0160.jpg');
const image150 = imageURL('photo/IMG-20241029-WA0161.jpg');
const image151 = imageURL('photo/IMG-20241029-WA0163.jpg');
const image152 = imageURL('photo/IMG-20241029-WA0164.jpg');
const image153 = imageURL('photo/IMG-20241029-WA0165.jpg');
const image154 = imageURL('photo/IMG-20241029-WA0166.jpg');
const image155 = imageURL('photo/IMG-20241029-WA0167.jpg');
const image156 = imageURL('photo/IMG-20241029-WA0168.jpg');
const image157 = imageURL('photo/IMG-20241029-WA0169.jpg');
const image158 = imageURL('photo/IMG-20241029-WA0170.jpg');
const image159 = imageURL('photo/IMG-20241029-WA0187.jpg');
const image160 = imageURL('photo/IMG-20241029-WA0188.jpg');



const GalleryContainer = styled.div`
  text-align: center;
  padding: 20px;
`;

const Heading = styled.h2`
  font-size: 2em;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  color: #666;
`;

const Categories = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
  font-size: 13px;

  @media (max-width: 768px) {
    align-items: center;
    gap: 10px;
  }
`;

const CategoryButton = styled.button`
  padding: 10px 15px;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
  width: 120px;
  height: 45px;
  font-size: 16px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(90deg, #f16dcb, #f16dcb);
    transition: width 0.3s ease;
  }
  &.active::after {
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: 100px;
    height: 40px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

const GalleryGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const GalleryRow = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const GalleryColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: ${props => props.large ? 2 : 1};
`;

const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    height: ${props => (props.large ? '400px' : '190px')};
    transition: transform 0.3s ease;
    @media (max-width: 768px) {
      height: auto;
    }
  }

  &:hover img {
    transform: scale(1.05); // Zoom effect on hover
  }

  &:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
  }
`;


const VideoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const VideoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;

 
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const VideoColumn = styled.div`
  flex: 1 1 48%;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const VideoItem = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

const LoadMoreButton = styled.button`
  padding: 10px 15px;
  background: linear-gradient(90deg, #f16dcb, #f16dcb);
  border: none;
  border-radius: 15px;
  margin: 20px auto;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  transition: background 0.3s ease;
  &:hover {
    background: linear-gradient(90deg, #ed39ba, #ed39ba);
  }
  @media (max-width: 1200px) {
    padding: 10px 18px;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 12px;
  }
  @media (max-width: 480px) {
    padding: 6px 14px;
    font-size: 10px;
  }
  @media (max-width: 320px) {
    padding: 4px 12px;
    font-size: 8px;
  }
`;

const allImages = [
  image1, image2, image3, image4, image5, image6, image7, image8, image9,
  image10, image11, image12, image13, image14, image15, image16, image17,
  image18, image19, image20, image21, image22, image23, image24, image25,
  image26, image27, image28, image29, image30, image31, image32, image33, image34, image35, 
  image36, image37, image38, image39, image40, image41, image42, image43, image44,
  image45, image46, image47, image48, image49, image50, image51, image52, image53,
  image54, image55, image56, image57, image58, image59, image60, image61, image62,
  image63, image64, image65, image66, image67, image68, image69, image70, image71,
  image72, image73, image74, image75, image76, image77, image78, image79, image80,
  image81, image82, image83, image84, image85, image86, image87, image88, image89,
  image90, image91, image92, image93, image94, image95, image96, image97, image98,
  image99, image100,
  image101, image102, image103, image104, image105, image106, image107, image108, image109,
  image110, image111, image112, image113, image114, image115, image116, image117, image118,
  image119, image120, image121, image122, image123, image124, image125, image126, image127,
  image128, image129, image130, image131, image132, image133, image134, image135, image136,
  image137, image138, image139, image140, image141, image142, image143, image144, image145,
  image146, image147, image148, image149, image150, image151, image152, image153, image154,
  image155, image156, image157, image158, image159, image160
];


const allVideos = [
  { src: "https://youtube.com/embed/_gqQiByItxc?list=PLX-Fsvk5Eo-Azx8yKHa3Htg8HnqbYfAdU" },
  { src: "https://youtube.com/embed/5LMls1knA9Q?list=PLX-Fsvk5Eo-AALUCRZ0ewQt8qTempnI4y" },
  { src: "https://youtube.com/embed/ZF2Cwmxmupo?list=PLX-Fsvk5Eo-AOQzT4VdcsnrA8HGGucXoC" },
  { src: "https://youtube.com/embed/742EVOHhZ3M?list=PLX-Fsvk5Eo-DmDQbNwkTqnB9TiBB4k2Rg" },
  { src: "https://youtube.com/embed/kXANweJGvcE?list=PLX-Fsvk5Eo-Bwr2YuVhsKqz18CR0-ZDy-" },
  { src: "https://youtube.com/embed/o4hcgISUbeg?list=PLX-Fsvk5Eo-CY23xVgDYqpZBjXiZC0-ge" },
  { src: "https://youtube.com/embed/X6XQJy3AEpg?list=PLX-Fsvk5Eo-CH1_5sKU9ZxPeQfv4OhDCu" },
  { src: "https://youtube.com/embed/M-1UOoPzUCg?list=PLX-Fsvk5Eo-CdtkBqg3zZQdAFGEL9ah8Q" },
  { src: "https://youtube.com/embed/F6qCqrJvv4E?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/_uCjVUw_o5I?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },

  { src: "https://youtube.com/embed/_uCjVUw_o5I?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/wCCqpIm4NMQ?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/tc8SQJx-Iq0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/wFl0GpCLHD0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/6cfIl4Jhz4Q?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/rKRdunHmT2I?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/bthG7j9haJU?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/nxT4DAHV-CE" },
  { src: "https://youtube.com/embed/nxT4DAHV-CE?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/EId8NptPUfU?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },

  { src: "https://youtube.com/embed/7qKUeldK43A?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/L7rBwbyBhEA?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/W0kIy1rVA2k?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/XE1G9cO2spc?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/Vfyhe-cgGWA?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/vR9inP3d5Ms?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/YfHU5xx9TSY?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/ox7cpE_V2yI?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/Uj-VXUt7lkI?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/_LCMZCmUk1E?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },

  { src: "https://youtube.com/embed/kfPhH0c6ago?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/VEF95-EgJgE?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/5gl-xaPy0oc?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/cvpT235AItU?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/Ch54-crdIhQ?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/1wtot31PcCY?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/nRNSPOXMTO0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/VvsrfCpDYGk?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/VN7lpG5Yx5c?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/8ITdt0jdwBI?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },

  { src: "https://youtube.com/embed/UL9Diif6RwE?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/S975tEnuTag?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/jVl-4qd4C6k" },
  { src: "https://youtube.com/embed/5Eki58IooCk" },
  { src: "https://youtube.com/embed/xP0iUxARiH0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/9Si3gC85LoM?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/0Sk578RrkTc?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/qAbEnuH1pw0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/1GSjPdyx29c?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/NIEkq2oCfMI?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/rlEnR3w_DM4?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" }
];


const getNextImageSet = (currentIndex, setSize) => {
  const endIndex = currentIndex + setSize;
  return allImages.slice(currentIndex, endIndex);
};

const getNextVideoSet = (currentIndex, setSize) => {
  const endIndex = currentIndex + setSize;
  return allVideos.slice(currentIndex, endIndex);
};

const Gallery = () => {
  const [currentSection, setCurrentSection] = useState('images');
  const [imageIndex, setImageIndex] = useState(0);
  const [loadedImageSets, setLoadedImageSets] = useState([
    getNextImageSet(0, 5),
    getNextImageSet(5, 5)
  ]);
  const [videoIndex, setVideoIndex] = useState(0);
  const [loadedVideoSets, setLoadedVideoSets] = useState(getNextVideoSet(0, 12));

  const handleImageClick = () => {
    setCurrentSection('images');
  };

  const handleVideoClick = () => {
    setCurrentSection('videos');
  };

  const handleLoadMoreImages = () => {
    const newSet = getNextImageSet(imageIndex + loadedImageSets.length * 5, 5);
    if (newSet.length > 0) {
      setLoadedImageSets(prevSets => [...prevSets, newSet]);
    }
  };

  const handleLoadMoreVideos = () => {
    const newSet = getNextVideoSet(videoIndex + loadedVideoSets.length, 3);
    if (newSet.length > 0) {
      setLoadedVideoSets(prevSets => [...prevSets, ...newSet]);
    }
  };

  return (
    <GalleryContainer>
      <Heading>Our Gallery</Heading>
 
      <Categories>
        <CategoryButton onClick={handleImageClick} className={currentSection === 'images' ? 'active' : ''}>Images</CategoryButton>
        <CategoryButton onClick={handleVideoClick} className={currentSection === 'videos' ? 'active' : ''}>Videos</CategoryButton>
      </Categories>
      {currentSection === 'images' && (
    <GalleryGrid>
      {loadedImageSets.map((imageSet, setIndex) => (
        <GalleryRow key={`set-${setIndex}`}>
          {imageSet.map((image, index) => (
            <GalleryColumn key={`image-${setIndex}-${index}`}>
              <GalleryItem>
                <img src={image} alt={`Image ${index + 1} of set ${setIndex}`} />
              </GalleryItem>
            </GalleryColumn>
          ))}
        </GalleryRow>
      ))}
    </GalleryGrid>
  )}
      {currentSection === 'videos' && (
        <VideoSection>
          {loadedVideoSets.map((video, index) => (
            index % 3 === 0 && (
              <VideoRow key={`video-row-${Math.floor(index / 3)}`}>
                <VideoColumn>
                  <VideoItem>
                    <iframe src={video.src} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                  </VideoItem>
                </VideoColumn>
                {loadedVideoSets[index + 1] && (
                  <VideoColumn>
                    <VideoItem>
                      <iframe src={loadedVideoSets[index + 1].src} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                    </VideoItem>
                  </VideoColumn>
                )}
                {loadedVideoSets[index + 2] && (
                  <VideoColumn>
                    <VideoItem>
                      <iframe src={loadedVideoSets[index + 2].src} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                    </VideoItem>
                  </VideoColumn>
                )}
              </VideoRow>
            )
          ))}
        </VideoSection>
      )}
      {currentSection === 'images' && loadedImageSets.flat().length < allImages.length && (
        <LoadMoreButton onClick={handleLoadMoreImages}>Load More Images →</LoadMoreButton>
      )}
      {currentSection === 'videos' && loadedVideoSets.length < allVideos.length && (
        <LoadMoreButton onClick={handleLoadMoreVideos}>Load More Videos →</LoadMoreButton>
      )}
    </GalleryContainer>
  );
};

export default Gallery;
